.custom-dropdown {
    display: block !important; /* Override the display */
    pointer-events: all !important; /* Allow pointer events */
  }
.partd-example {
    color: #666;
    font-size: 1.0 em;
    margin-top: 5px;
}

.floating-help-button {
  position: fixed;
  left: 20px;
  bottom: 20px;
  z-index: 1000;
}
.form-input-width {
    width: 100%; /* Adjust this as needed */
  }

.custom-form .form-item-custom {
    margin-bottom: 0px; /* Reduce space between form items */
  }