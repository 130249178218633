.partd-example {
  color: #666;
  font-size: 1.0em;
  margin-top: 5px;
}

.floating-help-button {
  position: fixed;
  left: 20px;
  bottom: 20px;
  z-index: 1000;
}

.status-message {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  color: grey; /* Default to grey for info messages */
}

.status-message.warning {
  color: #ff9800;
}

.status-message.error {
  color: #f44336;
}

.status-message.success {
  color: #4caf50;
}

.status-message .icon {
  margin-right: 8px;
}