/* PAPPortalPage.css */
.pap-portal-container {
    text-align: center;
    padding: 20px;
  }
  
  .upload-section {
    margin: 20px 0;
  }
  
  .upload-label {
    display: block;
    margin-bottom: 10px;
  }
  
  .upload-label input[type="file"] {
    margin-top: 5px;
  }
  
  .tracking-process {
    background-color: #f0f0f0;
    padding: 20px;
    margin-top: 20px;
  }
  
  .tracking-process h2 {
    margin-bottom: 15px;
  }
  
  .tracking-process p {
    margin-bottom: 10px;
  }
  