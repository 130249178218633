.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-header {
  padding-bottom: 2px;
}

.app-header a {
  text-decoration: none;
  color: currentColor;
}

.app-header-main {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  height: 64px;
  line-height: 64px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
}

.app-header-title {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: black;
}

.app-header-items {
  display: flex;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.app-header-item {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 12px;
  height: 100%;
  color: rgba(0, 0, 0, .88);
  cursor: pointer;
}

.app-header-item:before {
  position: absolute;
  top: 0;
  right: 12px;
  left: 12px;
  height: 2px;
  background-color: currentColor;
}

.app-header-item:hover {
  color: #1677ff;
}

.app-header-item:hover:before {
  content: '';
}

.app-layout {
  display: flex;
  flex: 1;
  padding: 24px 0;
}

.app-aside {
  width: 256px;
}

.app-main {
  flex: 1;
  padding: 0 20px;
}

.app-footer {
  padding: 20px 0;
  text-align: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.88);
}

@media screen and (max-width: 768px) {
  .app-aside {
    width: 60px; /* Smaller width for the sidebar on mobile devices */
  }

  .app-main {
    padding: 10px; /* Adjust the padding for the main content area */
    flex-direction: column; /* Stack sidebar and main content vertically */
  }

  .app-header-title {
    font-size: 16px; /* Smaller font size for the header title */
  }

  .app-header-items,
  .some-non-essential-class {
    display: none; /* Hide header items and non-essential elements */
  }

  .app-header-item {
    padding: 0 15px; /* Increase padding for larger touch targets */
    font-size: 16px; /* Increase font size for readability */
    line-height: 1.5; /* Adjust line height for better readability */
  }
}