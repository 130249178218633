.plan-results {
    margin-bottom: 20px;
  }
  
  .plan-details-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .plan-details-table th,
  .plan-details-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .plan-details-table th[colSpan="4"] {
    background-color: #f7f7f7;
    font-weight: bold;
  }
  
  .tooltip-icon {
    cursor: pointer;
    color: #888;
  }
  