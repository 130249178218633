/* PriorAuthorizationPage.css */
.downloadButton {
    padding: 10px;
    margin-top: 10px;
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
  }
  
  /* Other styles */
  